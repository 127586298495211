import { reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
    connected: false,
    fooEvents: [],
    barEvents: []
});

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === "production" ? undefined : "https://smash3000.ovh";
const URL  = "https://smash3000.ovh";
// const URL  = "ws://localhost:8082";

export const socket = io(URL);

socket.on("connect", () => {
    state.connected = true;
    console.log('Connected');
});

socket.on("disconnect", () => {
    state.connected = false;
    console.log('Disconnected');
});