
<script setup>
import { useStageStore } from "@/stores/stage";
import { useConnectionStore } from "@/stores/connection";
import { socket } from "@/socket/socket";
import { NConfigProvider, NGlobalStyle } from "naive-ui";
import {darkTheme} from 'naive-ui'
import { ref, onMounted } from 'vue'
import { frFR, dateFrFR } from 'naive-ui'
import {sayHello} from "@/services/concertService";
import {getSystemState} from "@/services/systemStateService";



let locale = ref(frFR)
let dateLocale = ref(dateFrFR)
let currentTheme = ref(darkTheme)
const stageStore = useStageStore();
const connectionStore = useConnectionStore();

// remove any existing listeners (after a hot module replacement)
socket.off();

stageStore.bindEvents();
connectionStore.bindEvents();

const refreshSystemState = () => {
  getSystemState().then(resp => {
      stageStore.pause = resp.data.pause.pause
      stageStore.stage = resp.data.current.stageTitle
      stageStore.moment = resp.data.current.moment
      stageStore.setlistSong = resp.data.current.setlistSong
      stageStore.setlist = resp.data.current.setlist
    stageStore.text = resp.data.text
  })
}

onMounted(() => {
  sayHello()
  refreshSystemState()
})

</script>


<template>
  <n-config-provider  :locale="locale" :date-locale="dateLocale" :theme="currentTheme">
    <n-global-style />
    <router-view/>
  </n-config-provider>
</template>


<style>
@font-face {
  font-family: "SilkScreen";
  src: local("SilkScreen"),
  url("@/fonts/slkscr.ttf") format("truetype");
}

body{
  width: 100vw;
}

#app {
  font-family: "SilkScreen", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
