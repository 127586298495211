<template>
  <n-layout class="layout" @click="nextSong">
    <n-layout-content class="content">
      <n-space justify="center" align="center" class="song-container">
        <n-text type="success" class="song-title">{{ current }}</n-text>
      </n-space>
    </n-layout-content>
  </n-layout>
</template>

<script>
import {
  NSpace, NLayout, NLayoutContent, NText
} from "naive-ui";

export default {
  name: "KrapoView",
  components: {NSpace, NText, NLayout, NLayoutContent },
  data() {
    return {
      current : "",
      done: [],
      songs: [
        "8 bit",
        "Biffy",
        "Bloody Wednesday",
        "Bonfire",
        "Canned Heat",
        "Confident",
        "Daft Punk",
        "D.a.n.c.e",
        "Dance with the Dead",
        "Day Break",
        "DjiDji",
        "Foals",
        "Fuya",
        "Ghost in the Shell",
        "Giorgio",
        "Gnossienne",
        "Going the Distance",
        "Helicopter",
        "Hybrid Stigmata",
        "Je vous aime ma mie",
        "Lone Digger",
        "Lovecats",
        "Magnolia forever",
        "Major Lazer",
        "Maniac",
        "Medley Jimmy",
        "MEGAMAN",
        "Nothing Else Matters",
        "Pressure in my head",
        "Pony",
        "Radio Video",
        "Rodrigo",
        "Schrodinger",
        "Shanti",
        "Starlight",
        "Sur la planche",
        "The Bay",
        "The Blood, the Sweat, the Tears",
        "Thunderstruck",
        "Toccata",
        "Tous les memes",
        "Turbo Killer",
        "Uptown Funk",
        "Under the Bridge",
        "We Are Your Friends",
        "War"
      ],
    }
  },
  methods:{
    nextSong(){
      if(this.songs.length === 0 )
      {
        this.current = "Y'en a plus, Rafraichis la page !"
      }
      var nextIndex = Math.floor(Math.random() * this.songs.length);
      var next = this.songs.at(nextIndex);
      this.done.push(next);
      this.songs.splice(nextIndex, 1);
      this.current = next;
    },

  },
  mounted() {
    this.nextSong()
  }
}
</script>

<style scoped>
.layout {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40vh;
}

.song-container {
  text-align: center;
}

.song-title {
  font-size: 6rem; /* Adjust font size as needed */
  font-family:Roboto;
}

@media (max-width: 600px) {
  .song-title {
    font-size: 4rem; /* Smaller font size for mobile */
  }
}
</style>
