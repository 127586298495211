<script setup>
import {onMounted} from "vue";
import {getSongs, addSong, editSong, deleteSong} from "@/services/songService";
import {ref} from "vue";
import {NCard, NGrid, NGi, NButton, NDrawer, NFormItem, NForm, NInput, NDrawerContent, NSpace, NSelect} from "naive-ui";
import {getStages} from "@/services/stageService";

const songs = ref([]);
const selected = ref([]);
const showDrawer = ref(false);
const size = 'large';
const songEdit = ref({title: '', duration : 0, bpm: 0, zgg: 0, stage: {}});
const stageOptions = ref([])
const stages = ref([])


// eslint-disable-next-line no-undef
const props = defineProps({
  simple: {type: Boolean, default: false},
  selection: {type: Boolean, default: false},
})

const editCard = (s) => {
  songEdit.value = {...s};
  showDrawer.value = true;
}

const saveSong = () => {
  if(songEdit.value.stage.id && !songEdit.value.stage.title)
  {
    songEdit.value.stage = stages.value.filter(s => s.id === songEdit.value.stage.id)[0]
  }
  if(songEdit.value.id)
  {
    editSong(songEdit.value, songEdit.value.id).then(() => {
      showDrawer.value = false
      songEdit.value = {stage:{}}
    })
  }
  else
  {
    addSong(songEdit.value).then(resp => {
      songs.value.push(resp.data)
      songEdit.value = {stage:{}}
      showDrawer.value = false
    })
  }
}

const removeSong = (id) => {
  if(id === undefined || id === null || id === '') return;
  deleteSong(id).then(() => {
    songs.value = songs.value.filter(s => s.id !== id)
    songEdit.value = {stage: {}}
    showDrawer.value = false
  })
}


const durationString = (duration) => {
  let minutes = Math.floor(duration / 60);
  let seconds = duration - minutes * 60;
  return minutes + "m" + seconds + "s";
}

const select = (s) => {
  if(!props.selection) return;
  if (selected.value.includes(s)) {
    selected.value = selected.value.filter(song => song.id !== s.id);
  } else {
    selected.value.push(s);
  }
}

onMounted(() => {
  getSongs().then(resp => {
    songs.value = resp.data;
    songs.value.forEach(s => {
      s.stage = s.stage !== null ? s.stage : {id: null, title: null};
    })
  })

  getStages().then(resp => {
    stages.value = resp.data
    stageOptions.value = resp.data.map(s => {
      return {label: s.title, value: s.id}
    })
  })
})

</script>

<template>
  <n-drawer v-if="!simple" v-model:show="showDrawer" :width="333">
    <n-drawer-content>
      <template #header>
          <p v-if="songEdit.id">Modifier le Morceau</p>
          <p v-else>Ajouter un Morceau</p>
      </template>
      <n-form
          ref="formRef"
          :model="songEdit"
          :size="size"
      >
        <n-form-item label="Titre" path="songEdit.title">
          <n-input v-model:value="songEdit.title" placeholder="Titre du morceau" />
        </n-form-item>
        <n-form-item label="Tempo" path="songEdit.bpm">
          <n-input v-model:value="songEdit.bpm" type="number" placeholder="Bpm" />
        </n-form-item>
        <n-form-item label="Durée" path="songEdit.duration">
          <n-input v-model:value="songEdit.duration" placeholder="Durée" />
        </n-form-item>
        <n-form-item label="Zgueg" path="songEdit.zgg">
          <n-input v-model:value="songEdit.zgg" placeholder="ZGG" />
          <!--          <star-notation :notation="songEdit.zgg" />-->
        </n-form-item>
        <n-form-item>
          <n-select v-model:value="songEdit.stage.id" :options="stageOptions"/>
        </n-form-item>
      </n-form>
      <template #footer>
        <n-space justify="space-between">
          <n-button @click="showDrawer=false">Annuler</n-button>
          <n-button @click="saveSong">Enregistrer</n-button>
          <n-button tertiary type="error" @click="removeSong(songEdit.id)" v-if="songEdit.id">Supprimer</n-button>
        </n-space>
      </template>
    </n-drawer-content>
  </n-drawer>
  <n-space justify="space-around">
    <div></div>
    <h3>Liste des Morceaux</h3>
    <n-button @click="editCard({title: '', duration : 0, bpm: 0, zgg: 0, stage: {}})">Ajouter</n-button>
  </n-space>
  <n-grid :x-gap="12" :y-gap="8" cols="2 s:3 m:4 l:6 xl:12" :responsive="simple ? 'self':'screen'">
    <n-gi class="card" :span="3" v-for="s in songs" :key="s.id">
      <n-card class="song_card" :class="selection ? (selected.includes(s) ? 'selected':'selectable') : ''" :title="s.title" @click="select(s)">
        <template v-if="!simple" #header-extra>
          {{s.zgg}}
        </template>
        <n-grid v-if="!simple" :cols="2">
          <n-gi>
            <h4>Tempo : </h4>
            <p>{{s.bpm}}</p>
          </n-gi>
          <n-gi>
            <h4>Durée : </h4>
            <p>{{durationString(s.duration)}}</p>
          </n-gi>
        </n-grid>
        <template #footer>
        </template>
        <template #action>
          <n-button v-if="!simple" @click.stop="editCard(s)">Edit</n-button>
        </template>
      </n-card>
    </n-gi>
  </n-grid>
</template>

<style scoped>
.selectable:hover{
  cursor: pointer;
  background-color: #b2b4b2;
  color: white;
}
.selected{
  cursor: pointer;
  background-color: #b2b4b2;
  color: white;
}

.drawerTitle p{
  margin: 6px 0;
}
</style>