<script setup>
import {NGrid, NGi, NCard, NIcon, NSpace} from "naive-ui";
import {computed, watch, onUnmounted, ref, defineProps} from "vue";
import {useStageStore} from "@/stores/stage";
import {Play,Pause} from "@vicons/fa";
import {Next32Regular, Previous32Regular} from "@vicons/fluent";
import STAGES from "@/utils/stages";


const props = defineProps({
  gaucher: {type: Boolean, default: false}
})
const gaucher = props.gaucher ? props.gaucher : ref(false)

const stageStore = useStageStore();

const pause = computed(() => {
  return stageStore.pause
})

const moment = computed(() => {
  return stageStore.moment
})

const message = computed(() => {
  return stageStore.text
})

const title = computed(() => {
  return stageStore.stage
})

const song = computed(() => {
  return stageStore.setlistSong
})

const nextSong = computed(() => {
  if(!stageStore.setlistSong)
    return {title: 'PROBLEME', bpm: 0, stage: null}
  const nextIndex = stageStore.setlistSong?.position + 1;
  if(nextIndex === undefined){
    return {title: 'PROBLEME', bpm: 0, stage: null}
  }
  if(stageStore.setlist?.setlistSongs?.length === undefined || nextIndex >= stageStore.setlist.setlistSongs.length)
    return {title: 'Fin du concert', bpm: 0, stage: null}
  return stageStore.setlist.setlistSongs[nextIndex].song
})

const pauseTitle = computed(() => {
  return pause.value ? 'REPRENDRE' : 'PAUSE'
})

const togglePause = () => {
  if(!stageStore.pause)
    stageStore.pauseSystem()
  else
    stageStore.resumeSystem()
}

const checkScoreAndNext = () => {
  console.log(stageStore.setlistSong)
  if(stageStore.setlistSong?.song?.stage?.hasScore === true){
    if(stageStore.moment !== 100)
    {
      stageStore.sendMoment(100)
    }
    else {
      stageStore.nextSong()
    }
  }
  else{
    stageStore.nextSong()
  }
}

/////////////////


// The isBlinking state to track if the metronome is currently active
const isBlinking = ref(false);

// Use a ref for the interval ID so it's reactive and can be cleared
const blinkInterval = ref(null);

// Function to start the metronome
function startMetronome(bpm) {
  clearInterval(blinkInterval.value); // Clear any existing interval
  const blinkRate = (60 / bpm) * 1000; // Convert BPM to milliseconds
  blinkInterval.value = setInterval(() => {
    const blinkDiv = document.getElementById("blinkDiv");
    blinkDiv.style.opacity = blinkDiv.style.opacity == "1" ? "0" : "1";
  }, blinkRate / 2);
  isBlinking.value = true;
}

// Function to stop the metronome
function stopMetronome() {
  clearInterval(blinkInterval.value);
  isBlinking.value = false;
  const blinkDiv = document.getElementById("blinkDiv");
  if (blinkDiv) {
    blinkDiv.style.opacity = "1"; // Reset to visible
  }
}

// Watcher to react to changes in BPM
watch(() => stageStore.setlistSong?.song?.bpm, (newBpm) => {
  console.log(newBpm)
  console.log(isBlinking.value)
  if (newBpm && newBpm > 0) {
    startMetronome(newBpm);
  } else if (!newBpm || newBpm <= 0) {
    stopMetronome();
  }
});

// Cleanup on component unmount
onUnmounted(() => {
  stopMetronome();
});

////////////////

</script>

<template>
  <n-space class="fullscreen" justify="space-between" vertical>
    <n-grid class="mb-5" responsive="screen" x-gap="12" y-gap="12" :cols="7">
      <n-gi class="admin_button" :span="2" v-if="gaucher">
        <n-grid responsive="self" x-gap="0" y-gap="12" :cols="1">
          <n-gi>
            <n-card class="card_button" :span="1" @click="togglePause">
              <n-icon size="7vh" >
                <Pause v-if="!pause"/>
                <Play v-else/>
              </n-icon>
              <p class="button_label">{{ pauseTitle }}</p>
            </n-card>
          </n-gi>
<!--          <n-gi>-->
<!--            <n-card class="card_button" :span="1">-->
<!--              Coucou-->
<!--            </n-card>-->
<!--          </n-gi>-->
        </n-grid>
      </n-gi>
      <n-gi class="info_square" :span="5">
        <div class="">
          <h2>Prochain</h2>
          <h3>{{nextSong.title}} : {{nextSong.bpm}} bpm</h3>
          <h3>Projo : {{nextSong.stage === null ? 'Non défini' : nextSong.stage.title}}</h3>
        </div>
      </n-gi>
      <n-gi class="admin_button" :span="2" v-if="!gaucher">
        <n-grid responsive="self" x-gap="0" y-gap="12" :cols="1">
          <n-gi>
            <n-card size="small" class="card_button" :span="1" @click="togglePause">
              <n-icon size="7vh" >
                <Pause v-if="!pause"/>
                <Play v-else/>
              </n-icon>
              <p>{{ pauseTitle }}</p>
            </n-card>
          </n-gi>
<!--          <n-gi>-->
<!--            <n-card size="small" class="card_button" :span="1">-->
<!--              Coucou-->
<!--            </n-card>-->
<!--          </n-gi>-->
        </n-grid>
      </n-gi>
      <n-gi class="info_square" :span="7">
        <div class="" v-if="song.song">
          <h2>{{song.position}}. {{song.song.title}} : {{song.song.bpm}} bpm <span id="blinkDiv"></span></h2>
          <h3>{{title}}</h3>
          <p>{{ message }}</p>
        </div>
      </n-gi>
      <n-gi class="admin_button" :span="7">
        <n-card size="small" class="card_button">
          <h3>{{title}}</h3>
        <p v-if="moment === 100">AFFICHAGE SCORE</p>
          <p v-if="title === STAGES.FLORAL_FURY && moment === 0" @click="stageStore.sendMoment(1)">Passez au chox de solo</p>
          <p v-else-if="title === STAGES.FLORAL_FURY && moment === 1" @click="stageStore.sendMoment(2)">Passez au solo</p>
          <p v-else-if="title === STAGES.FLORAL_FURY && moment === 2" @click="stageStore.sendMoment(0)">Retour au truc normal</p>
        </n-card>
      </n-gi>
    </n-grid>
    <n-grid responsive="screen" x-gap="12" y-gap="12" :cols="2">
      <n-gi>
        <n-card size="small" class="card_button" :span="1" @click="stageStore.previousSong()">
          <n-icon size="7vh" >
            <Previous32Regular/>
          </n-icon>
        </n-card>
      </n-gi>
      <n-gi>
        <n-card size="small" class="card_button" :span="1" @click="checkScoreAndNext">
          <n-icon size="7vh" >
            <Next32Regular/>
          </n-icon>
        </n-card>
      </n-gi>
    </n-grid>
  </n-space>
</template>

<style scoped>
.fullscreen{
  height: 80vh;
}

.button_label{
  margin: 0;
  padding: 0;
}

.card_button{
  cursor: pointer;
  padding: 0;
  width: 100%;
}


.info_square{
  height: 100%;
  vertical-align: center;
}

.info_square div{
  border: #54585a solid 2px;
  border-radius: 10px;
  padding: 1vh 1vw;
}

.info_square h2{
  margin: 0;
  padding: 0;
  font-size: 2em;
}

.info_square p{
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 1.5em;
}

#blinkDiv{
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #63E2B7;
  border-radius: 50%;
}

</style>