import {API_BASE_URL} from "@/utils/contants";
import axios from "axios";

export const BASE_SETLIST_URL = API_BASE_URL + '/setlists'
export const getSetlists = function(){
    return axios.get(BASE_SETLIST_URL)
}

export const editSetlist = function(setlist, id){
    return axios.put(BASE_SETLIST_URL + '/edit/'+id, setlist)
}

export const addSetlist = function(setlist){
    return axios.post(BASE_SETLIST_URL, setlist)
}

export const deleteSetlist = function(id){
    return axios.delete(BASE_SETLIST_URL + '/'+id)
}

export const addSongtoSetlist = function(setlist_id, songTitle){
    return axios.post(BASE_SETLIST_URL+'/songs/'+setlist_id+'/add/'+songTitle)
}

export const removeSongfromSetlist = function(setlist_id, songTitle){
    return axios.delete(BASE_SETLIST_URL+'/songs/'+setlist_id+'/remove/'+songTitle)
}

export const moveSonginSetlist = function(setlist_id, songTitle, position){
    return axios.put(BASE_SETLIST_URL+'/songs/'+setlist_id+'/move/'+songTitle+'/'+position)
}
