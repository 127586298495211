<script setup>
import {ref, defineProps} from "vue";
import {NSwitch} from "naive-ui";

const props = defineProps(['gaucher'])
const gaucher = ref(props.gaucher)
</script>

<template>
<div>
  <n-switch v-model:value="gaucher" @update:value="$emit('gaucher')"/>
</div>
</template>

<style scoped>

</style>