const STAGES = {
    BLACK_SCREEN : "BlackScreen",

    LOGO : "Logo",

    LEVEL_SELECT : "LevelSelect",

    MOVING_LOGO : "MovingLogo",

    SHOVEL_KNIGHT : "ShovelKnight",

    DONKEY_KONG : "DonkeyKong",

    FLORAL_FURY : "FloralFury",

    LUIGI_MANSION : "LuigisMansion",

    LOADING_SCREEN : "LoadingScreen",

    RAYMAN : "Rayman",

    SONIC : "Sonic",

    QUIZZ : "Quizz",
}

export default STAGES;