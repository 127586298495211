import {API_BASE_URL} from "@/utils/contants";
import axios from "axios";

export const BASE_BUTTON_URL = API_BASE_URL + '/songs'
export const getSongs = function(){
    return axios.get(BASE_BUTTON_URL)
}

export const editSong = function(song, id){
    return axios.put(BASE_BUTTON_URL + '/edit/'+id, song)
}

export const addSong = function(song){
    return axios.post(BASE_BUTTON_URL+'/add', song)
}

export const deleteSong = function(id){
    return axios.delete(BASE_BUTTON_URL + '/' + id)
}
