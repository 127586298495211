<script setup>
import DiagManette from "@/components/gamepads/DiagManette.vue";
import DPadManette from "@/components/gamepads/DPadManette.vue";
import PreciseManette from "@/components/gamepads/PreciseManette.vue";
import ShakeManette from "@/components/gamepads/ShakeManette.vue";
import {useConnectionStore} from "@/stores/connection";
import {computed, ref, defineProps} from "vue";
import {useStageStore} from "@/stores/stage";

const props = defineProps({
  send: {type: Boolean, default: true},
})

const connectionStore = useConnectionStore();
const stageStore = useStageStore();


const stage = computed(() => {
  return connectionStore !== null ? connectionStore.isConnected ? stageStore.stage : 'NoConcert' : 'NoConcert'
})

const pause = computed(() => {
  return stageStore.pause
})

const send = ref(props.send)

connectionStore.bindEvents();
stageStore.bindEvents();
</script>

<template>
  <div class="fullscreen" v-if="pause === true">
    <h1 id="pause">PAUSE</h1>
  </div>
  <div v-else>
    <div class="fullscreen" v-if="stage ==='NoConcert' && send">
      <h1 id="no_concert">Il n'y a pas de concert en cours, revenez plus tard !!</h1>
    </div>
    <diag-manette v-else-if="stage" :stage="stage" :send="send"/>
    <precise-manette v-else-if="stage === 2"/>
    <shake-manette v-else-if="stage ===3"/>
    <d-pad-manette v-else/>
  </div>

</template>

<style scoped>
#dev_test{
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  color: white;
  font-weight: bold;
  font-size: larger;
  margin: 20px;
}
.fullscreen{
  background-color: #707372;
  position: absolute;
  display: grid;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#pause{
  position: absolute;
  z-index: 10;
  font-size: 10vh;
  color: #514689;
  top: 30%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
}

#no_concert{
  position: absolute;
  z-index: 10;
  color: #514689;
  top: 30%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
}
</style>
