<script setup>
import { NMenu} from "naive-ui";
import { ref } from 'vue'
import SongList from "@/components/admin/SongList.vue";
import SetlistList from "@/components/admin/SetlistList.vue";

const activeKey = ref('songs')

const menuOptions = [
  {
    key: 'songs',
    label: 'Songs',
  },
  {
    key: 'setlists',
    label: 'Setlists',
  },
]

</script>

<template>
  <div :span="12">
    <n-menu responsive v-model:value="activeKey" mode="horizontal" :options="menuOptions" />
  </div>
  <div v-if="activeKey==='songs'" :span="12">
    <SongList />
  </div>
  <div v-else-if="activeKey === 'setlists'">
    <SetlistList />
  </div>
</template>

<style scoped>

</style>