<script setup>

import {NButton, NForm, NFormItem, NGi, NGrid, NInput, NSpace, NStep, NSteps} from "naive-ui";
import {computed, onMounted, ref} from "vue";
import SetlistList from "@/components/admin/SetlistList.vue";
import {getTodays, updateConcert, updateConcertSetlist} from "@/services/concertService";
import {useStageStore} from "@/stores/stage";

const concert = ref({name: '', location: ''});

const currentStatus = 'process';

const current = ref(1);

const stageStore = useStageStore();

const canContinue = computed(()=>{
  //return true if env is dev
  //if(process.env.NODE_ENV === 'development') return true;
  if(current.value === 1){
    return concert.value.name !== null && concert.value.name !== '' && concert.value.location !== null && concert.value.location !== ''
  }
  if(current.value === 2){
    return stageStore.setlist !== null
  }
  return true
})

const lessgo = () => {
  console.log(concert.value)
  if(current.value === 1){
    updateConcert(concert.value.id, concert.value).then(resp => {
      concert.value = resp.data
      current.value++
    })
  }
  if(current.value === 2){
    updateConcertSetlist(concert.value.id, stageStore.setlist.id).then(resp => {
      concert.value = resp.data
      current.value++
    })
  }
}

onMounted(() => {
  getTodays().then(resp => {
    concert.value = resp.data
  })
})

</script>

<template>
  <h2>INITIALISATION</h2>
  <n-space vertical>
    <n-grid :cols="8" class="">
      <n-gi :span="2" v-if="current > 1" :offset="2"><n-button secondary  @click="current--">Retour</n-button></n-gi>
      <n-gi :span="2" :offset="current === 1 ? 3 : 0"><n-button :disabled="!canContinue" @click="lessgo">Continuer</n-button></n-gi>
    </n-grid>
    <n-grid class="forms"  :cols="6">
      <n-gi class="steps" :span="1">
        <n-steps vertical :current="current" :status="currentStatus">
          <n-step
          />
          <n-step
          />
          <n-step
          />
        </n-steps>
      </n-gi>
      <n-gi v-if="current===1" :span="4">
        <n-form
            ref="formRef"
            :model="concert"
        >
          <n-form-item label="Nom du Public" path="concert.name">
            <n-input v-model:value="concert.name" placeholder="Nom du Public" />
          </n-form-item>
          <n-form-item label="Lieu du concert" path="concert.location">
            <n-input v-model:value="concert.location" placeholder="Lieu du Concert" />
          </n-form-item>
        </n-form>
      </n-gi>
      <n-gi v-else-if="current===2" :span="4">
        <SetlistList :simple="true" :selection="true" />
      </n-gi>
      <n-gi v-else-if="current===3" :span="4">
        <h1>C'est fini c'est bon va mettre ton costume maintenant</h1>
      </n-gi>
    </n-grid>
  </n-space>
</template>

<style scoped>
.steps{
  margin-left: 2vw;
  margin-right: 2vw;
  text-align: center;
}

.forms{
  margin-top: 5vh;
}
</style>