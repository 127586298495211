<template>
  <diag-manette @pass="checkPass" v-if="lockedOrDev" :send="false"></diag-manette>
  <div class="fullscreen" v-else>
    <div :span="12">
      <n-menu responsive v-model:value="activeKey" mode="horizontal" :options="menuOptions" />
    </div>
    <div v-if="activeKey==='init'" :span="12">
     <ConcertInitialisation />
    </div>
    <div v-else-if="activeKey === 'commands'">
      <AdminCommands :gaucher="gaucher"/>
    </div>
    <div v-else-if="activeKey==='songsetlist'">
      <SongSetlist />
    </div>
    <div v-else-if="activeKey==='stages'">
      <StageList />
    </div>
    <div v-else-if="activeKey==='params'">
      <AdminParameters @gaucher="gaucher = !gaucher"/>
    </div>
  </div>
</template>

<script>
import {unlockAdmin} from "@/services/buttonService";
import StageList from "@/components/admin/StageList.vue";
import DiagManette from "@/components/gamepads/DiagManette.vue";
import {NIcon, NMenu} from "naive-ui";
import {MusicalNote as NoteIcon} from '@vicons/ionicons5'
import {Home as HomeIcon,
  Book as BookIcon} from "@vicons/ionicons5";
import {Cogs as CogIcon,
        Gamepad as HorseIcon} from "@vicons/fa";

import { h } from 'vue'
import ConcertInitialisation from "@/components/admin/ConcertInitialisation.vue";
import AdminCommands from "@/components/admin/AdminCommands.vue";
import AdminParameters from "@/components/admin/AdminParameters.vue";
import SongSetlist from "@/components/admin/SongSetlist.vue";
export default {
  name: "AdminView",
  components: {
    SongSetlist,
    AdminCommands,
    ConcertInitialisation,
    StageList,
    AdminParameters,
    DiagManette, NMenu},
  data() {
    return {
      gaucher: false,
      locked: true,
      chapter_number: 0,
      text: '',
      confirm: true,
      concert: {},
      activeKey : 'commands',
      current: 1,
      currentStatus: 'process',
      menuOptions: [
        {
          key: 'init',
          icon: this.renderIcon(HomeIcon)
        },
        {
          key: 'commands',
          icon: this.renderIcon(HorseIcon)
        },
        {
          key: 'songsetlist',
          icon: this.renderIcon(NoteIcon)
        },
        {
          key: 'stages',
          icon: this.renderIcon(BookIcon)
        },
        {
          key: 'params',
          icon: this.renderIcon(CogIcon)
        },
      ]
    }
  },
  computed:{
    lockedOrDev(){
      return this.locked && process.env.NODE_ENV !== 'development'
    }
  },
  methods:{
    renderIcon (icon) {
      return () => h(NIcon, null, { default: () => h(icon) })
    },
    checkPass(pass){
      unlockAdmin(pass).then(resp => {
        this.locked = !resp.data
      }).catch({
      })
    }
  },
}
</script>

<style scoped>
.fullscreen{
  font-family: Arial;
  padding: 1vw 1vh;
}

.admin_text p{
  font-family: "Arial", Helvetica;
  width: 90%;
}
</style>
