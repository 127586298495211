import { defineStore } from "pinia";
import { socket } from "@/socket/socket";

export const useStageStore = defineStore("stage", {
    state: () => ({
        pause: false,
        text: '',
        stage: 'BlackScreen',
        setlist: {},
        setlistSong: {},
        moment: 0,
    }),

    actions: {
        bindEvents() {

            socket.on('concert_state', (...args) => {
                let current = JSON.parse(args[0])
                this.stage = current.stageTitle
                this.moment = current.moment
                this.setlistSong = current.setlistSong
                this.setlist = current.setlist
            })

            socket.on('stage', (...args) => {
                this.stage = args[0];
                this.moment = 0;
            })

            socket.on('moment', (...args) => {
                this.moment = args[0];
            })

            socket.on('text', (...args) => {
                this.text = args[0];
            })


            socket.on('pause', () => {
                this.pause = true;
            })

            socket.on('resume', () => {
                this.pause = false;
            })
        },
        sendSetlist(setlist_id) {
            socket.emit('setlist', setlist_id+'');
        },
        sendStage(stage) {
            socket.emit('stage', stage);
        },
        sendMoment(moment) {
            socket.emit('moment', moment);
        },
        pauseSystem() {
            socket.emit('pause');
        },
        resumeSystem() {
            socket.emit('resume');
        },
        nextSong() {
            socket.emit('next');
        },
        previousSong() {
            socket.emit('previous');
        }

    }
});