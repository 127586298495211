import { defineStore } from "pinia";
import { socket } from "@/socket/socket";

export const useConnectionStore = defineStore("connection", {
    state: () => ({
        isConnected: false,
    }),

    actions: {
        bindEvents() {
            socket.on("connect", () => {
                this.isConnected = true;
            });

            socket.on("disconnect", () => {
                this.isConnected = false;
            });
        },

        connect() {
            console.log('on tente de se connecter')
            socket.connect({}, function(frame) {
                this.isConnected = true
                console.log('Connected: ' + frame);
                /* socket.subscribe('/topic/messages', function (messageOutput) {
                    // showMessageOutput(JSON.parse(messageOutput.body));
                    console.log(messageOutput)
                });*/
            })
        },

        disconnect() {
            if(socket != null) {
                socket.disconnect();
            }
            this.isConnected = false
            console.log("Disconnected");
        }
    },
});