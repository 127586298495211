import {API_BASE_URL} from "@/utils/contants";
import axios from "axios";

export const BASE_BUTTON_URL = API_BASE_URL + '/stages'
export const getStages = function(){
    return axios.get(BASE_BUTTON_URL + '')
}

export const editStage = function(stage, id){
    return axios.put(BASE_BUTTON_URL + '/'+id, stage)
}

export const addStage = function(stage){
    return axios.post(BASE_BUTTON_URL + '', stage)
}
