<script setup>
import {onMounted} from "vue";
import {getStages, addStage, editStage} from "@/services/stageService";
import {ref} from "vue";
import {NCard, NGrid, NGi, NButton, NDrawer, NFormItem, NForm, NInput, NDrawerContent, NSwitch, NSpace } from "naive-ui";
import {useStageStore} from "@/stores/stage";

const stages = ref([]);
const selected = ref([]);
const showDrawer = ref(false);
const size = 'large';
const stageEdit = ref({title: '', hasScore:false});
const stageStore = useStageStore();

// eslint-disable-next-line no-undef
const props = defineProps({
  selection: {type: Boolean, default: false},
})

const editCard = (s) => {
  stageEdit.value = {...s};
  showDrawer.value = true;
}

const sendStage = (stageTitle) => {
  stageStore.sendStage(stageTitle)
}

const saveStage = () =>{
  if(stageEdit.value.id == null)
  {
    addStage(stageEdit.value).then(resp => {
      stages.value.push(resp.data)
      stageEdit.value = {}
      showDrawer.value = false
    })
  }
  else
  {
    editStage(stageEdit.value, stageEdit.value.id).then(resp => {
      stages.value = stages.value.map(s => s.id === resp.data.id ? resp.data : s)
      stageEdit.value = {}
      showDrawer.value = false
    })
  }
}


const select = (s) => {
  if(!props.selection) return;
    if (selected.value.includes(s)) {
      selected.value = selected.value.filter(stage => stage.id !== s.id);
    } else {
      selected.value.push(s);
    }
}

onMounted(() => {
  getStages().then(resp => {
    stages.value = resp.data;
  })
})

</script>

<template>
  <n-drawer v-model:show="showDrawer" :width="300">
    <n-drawer-content>
      <template #header>
        Modifier le Stage
      </template>
      <n-form
          ref="formRef"
          :model="stageEdit"
          :size="size"
      >
        <n-form-item label="Titre" path="stageEdit.title">
          <n-input v-model:value="stageEdit.title" placeholder="Titre du stage" />
        </n-form-item>
        <n-form-item>
          <n-switch v-model:value="stageEdit.hasScore">
            <template #checked>
              Le niveau a un score
            </template>
            <template #unchecked>
              Le niveau n'a pas de score
            </template>
          </n-switch>
        </n-form-item>
      </n-form>
      <template #footer>
        <n-space justify="space-between">
          <n-button @click="showDrawer=false">Annuler</n-button>
          <n-button @click="saveStage">Enregistrer</n-button>
        </n-space>
      </template>
    </n-drawer-content>
  </n-drawer>
  <n-grid :cols="6" responsive="screen">
    <n-gi :span="4">
      <h3>Liste des Stage</h3>
    </n-gi>
    <n-gi :span="2">
      <n-button @click="showDrawer=true">Ajouter</n-button>
    </n-gi>
  </n-grid>
  <n-grid :x-gap="12" :y-gap="8" cols="s:3 m:4 l:6 xl:12" responsive="screen">
    <n-gi class="card" :span="3" v-for="s in stages" :key="s.id">
      <n-card class="stage_card" :class="selection ? (selected.includes(s) ? 'selected':'selectable') : ''" :title="s.title" @click="select(s)">
        <n-grid :cols="2">
          <n-gi>
            A un score : {{s.hasScore?'Oui':'Non'}}
          </n-gi>
        </n-grid>
        <template #footer>
        </template>
        <template #action>
          <n-space justify="space-between">
          <n-button @click.stop="editCard(s)">Edit</n-button>
          <n-button type="primary" @click.stop="sendStage(s.title)">Go Live</n-button>
          </n-space>
        </template>
      </n-card>
    </n-gi>
  </n-grid>
</template>

<style scoped>
.selectable:hover{
  cursor: pointer;
  background-color: #b2b4b2;
  color: white;
}
.selected{
  cursor: pointer;
  background-color: #b2b4b2;
  color: white;
}
</style>