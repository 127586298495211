import {API_BASE_URL} from "@/utils/contants";
import axios from "axios";

export const BASE_CONCERT_URL = API_BASE_URL + '/concert'

export const sayHello = function(){
    return axios.get(BASE_CONCERT_URL+'/hello')
}

export const getTodays = function() {
    return axios.get(BASE_CONCERT_URL + '/today')
}

export const updateConcert = function (concert_id, concert){
    return axios.put(BASE_CONCERT_URL+`/update/${concert_id}`,concert)
}

export const updateConcertSetlist = function (concert_id, setlist_id){
    return axios.put(BASE_CONCERT_URL+`/update/${concert_id}/setlist/${setlist_id}`)
}
