<script setup>
import {onMounted} from "vue";
import {ref} from "vue";
import {
  getSetlists,
  addSetlist,
  editSetlist,
  moveSonginSetlist,
  removeSongfromSetlist, addSongtoSetlist, deleteSetlist
} from "@/services/setlistService";
import {NCard, NGrid, NGi, NButton, NIcon, NList, NListItem, NDrawer, NForm, NInput, NDrawerContent, NSpace } from "naive-ui";
import draggable from 'vuedraggable'
import {getSongs} from "@/services/songService";
import { TrashBinOutline as TrashIcon, Add, MusicalNote } from '@vicons/ionicons5';
import {useStageStore} from "@/stores/stage";

const setlists = ref([]);
const songs = ref([]);
const showDrawer = ref(false);
const showSongs = ref(false);
const selected = ref({})
const setlistEdit = ref({name: ''});
const drag = ref(false)
const stageStore = useStageStore();


// eslint-disable-next-line no-undef,no-unused-vars
const props = defineProps({
  simple: {type: Boolean, default: false},
  selection: {type: Boolean, default: false},
})

const editCard = (s) => {
  setlistEdit.value = {...s};
  showDrawer.value = true;
}

const saveSetlist = () => {
  if(setlistEdit.value.id === undefined || setlistEdit.value.id === null || setlistEdit.value.id === '')
  {
    addSetlist(setlistEdit.value).then(resp => {
      setlists.value.push(resp.data)
      setlistEdit.value = resp.data
    })
  }
  else
  {
    editSetlist(setlistEdit.value, setlistEdit.value.id).then(resp=> {
      showDrawer.value = false
      setlistEdit.value = resp.data
    })
  }
}

const removeSetlist = (id) => {
  if(id === undefined || id === null || id === '') return;
  deleteSetlist(id).then(() => {
    setlists.value = setlists.value.filter(s => s.id !== id)
    setlistEdit.value = {}
  })
}


const durationString = (duration) => {
  let minutes = Math.floor(duration / 60);
  let seconds = duration - minutes * 60;
  return minutes + "m" + seconds + "s";
}

const setlistDuration = (setlist) => {
  const duration = setlist.setlistSongs.reduce((acc, s) => acc + s.song.duration, 0);
  return durationString(duration);
}

const addSong = (songId) => {
  //if song is already in setlist, remove it from setlist
  if(songIndexInSetlist(songId) !== -1)
  {
    removeSong(songId)
    return;
  }
  addSongtoSetlist(setlistEdit.value.id, songId).then(() => {
    setlists.value = setlists.value.map(s => {
      if(s.id === setlistEdit.value.id)
      {
        s.setlistSongs = s.setlistSongs.concat({song: songs.value.filter(s => s.id === songId)[0], position: s.setlistSongs.length})
        setlistEdit.value = s
      }
      return s;
    })
  })
}

const songIndexInSetlist = (songId) => {
  if(setlistEdit.value.id === undefined || setlistEdit.value.id === null || setlistEdit.value.id === '') return -1;
  return setlistEdit.value.setlistSongs.findIndex(s => s.song.id === songId)
}


const removeSong = (songId) => {
  removeSongfromSetlist(setlistEdit.value.id, songId).then(() => {
    setlists.value = setlists.value.map(s => {
      if(s.id === setlistEdit.value.id)
      {
        s.setlistSongs = s.setlistSongs.filter(ss => ss.song.id !== songId)
        setlistEdit.value = s
      }
      return s;
    })
  })
}

const moveSong = (event) => {
  if(event.moved !== null)
  {
    const moved = event.moved;
    const oldIndex = moved.oldIndex;
    const newIndex = moved.newIndex;
    const el = moved.element;
    moveSonginSetlist(setlistEdit.value.id, el.song.id, newIndex).then(() => {
      const setlist = setlistEdit.value;
      const song = setlist.setlistSongs[oldIndex];
      setlist.setlistSongs.splice(oldIndex, 1);
      setlist.setlistSongs.splice(newIndex, 0, song);
      setlist.setlistSongs.forEach((s, i) => s.position = i);
    }).catch(() => {

    })
  }
}

const sendSetlist = (setlistId) => {
  selected.value = setlists.value.filter(s => s.id === setlistId)[0]
  stageStore.sendSetlist(setlistId)
}

onMounted(() => {
  getSetlists().then(resp => {
    setlists.value = resp.data;
  })

  getSongs().then(resp => {
    songs.value = resp.data
  })
})

</script>

<template>
  <n-drawer v-if="!simple" v-model:show="showDrawer" :width="333">
    <n-drawer-content>
      <template #header>
        <p v-if="setlistEdit.id">Modifier la Setlist</p>
        <p v-else>Ajouter une Setlist</p>
      </template>
      <n-space justify="space-evenly" vertical v-if="!showSongs">
        <n-form
            ref="formRef"
            :model="setlistEdit"
        >
          <n-space justify="space-between">
            <n-input v-model:value="setlistEdit.name" placeholder="Nom de la Setlist" />
            <n-button @click="showSongs=true" v-if="setlistEdit.id">
              <template #icon>
                <n-icon><Add /></n-icon>
                <n-icon><MusicalNote /></n-icon>
              </template>
            </n-button>
          </n-space>
        </n-form>
        <draggable :list="setlistEdit.setlistSongs?.sort((a, b) => a.position - b.position)"
                   @change="moveSong"
                   @start="drag=true"
                   @end="drag=false"
                   item-key="position">
          <template v-slot:item="{element}">
            <n-card class="setlistSongcard" style="cursor: move">
              <n-space justify="space-between">
                <p>{{ element.position + 1 }}. {{ element.song.title }}</p>
                <n-button circle @click="removeSong(element.song.id)">
                  <template #icon>
                    <n-icon><TrashIcon /></n-icon>
                  </template>
                </n-button>
              </n-space>
            </n-card>
          </template>
        </draggable>
      </n-space>
      <n-space justify="space-evenly" vertical v-else>
        <n-list>
          <n-list-item :class="songIndexInSetlist(song.id) !== -1 ? 'selected' : 'unselected'" v-for="song in songs" :key="song.id" @click="addSong(song.id)" style="cursor: pointer">
            <span v-if="songIndexInSetlist(song.id) !== -1">{{songIndexInSetlist(song.id) + 1}}.</span> {{ song.title }}
          </n-list-item>
        </n-list>
      </n-space>
      <template #footer>
        <n-space justify="space-between">
          <n-button @click="showDrawer=false">Annuler</n-button>
          <n-button @click="saveSetlist">Enregistrer</n-button>
          <n-button tertiary type="error" @click="removeSetlist(setlistEdit.id)" v-if="setlistEdit.id">Supprimer</n-button>
        </n-space>
      </template>
    </n-drawer-content>
  </n-drawer>
  <n-space justify="space-around">
    <div></div>
    <h3>Liste des Setlists</h3>
    <n-button @click="editCard({name: '', setlistSongs: []})">Ajouter</n-button>
  </n-space>
  <n-grid :x-gap="12" :y-gap="8" cols="6 xl:12" :responsive="simple ? 'self':'screen'">
    <n-gi class="card" :span="3" v-for="s in setlists" :key="s.id">
      <n-card class="setlist_card" :class="selection ? (selected === s ? 'selected':'selectable') : ''" :title="s.name">
        <n-grid :cols="2">
          <n-gi>
            <h4>Morceaux : {{ s.setlistSongs.length }}</h4>
          </n-gi>
          <n-gi>
            <h4>Durée : {{ setlistDuration(s) }}</h4>
          </n-gi>
        </n-grid>
        <template #footer>
          <n-button @click="s.seeSong = !s.seeSong">
            <span v-if="!s.seeSong">Voir les morceaux</span>
            <span v-else> Cacher les morceaux</span>
          </n-button>
          <n-list v-if="s.seeSong">
            <n-list-item v-for="setlistSong in s.setlistSongs?.sort((a, b) => a.position - b.position)" :key="setlistSong.position">
              <h3>{{setlistSong.position + 1}}. {{ setlistSong.song.title }}</h3>
            </n-list-item>
          </n-list>
        </template>
        <template #action>
          <n-space justify="space-evenly">
            <n-button v-if="!simple" @click.stop="editCard(s)">Edit</n-button>
            <n-button secondary @click="sendSetlist(s.id)">ON LA JOUE !</n-button>
          </n-space>
        </template>
      </n-card>
    </n-gi>
  </n-grid>
</template>

<style scoped>
.selectable:hover{
  box-sizing: border-box;
  border: 2px #b2b4b2 solid;
  color: white;
}
.selected{
  box-sizing: border-box;
  border: 3px #b2b4b2 solid;
  color: white;
}

.setlistSongcard{
  margin-top: 5px;
  margin-bottom: 5px;
  p {
    margin: 0;
  }
}

.drawerTitle p{
  margin: 6px 0;
}


</style>